import { default as _91filmGroupId_935Te4kwU3qmMeta } from "/build/incl/pages/admin/audience-favorite/[filmGroupId].vue?macro=true";
import { default as indexGT0UMmJUoCMeta } from "/build/incl/pages/admin/audience-favorite/index.vue?macro=true";
import { default as checkinQlOB2SdPFPMeta } from "/build/incl/pages/admin/awards-ceremony/checkin.vue?macro=true";
import { default as exit_45interviewsoTDveIGw7WMeta } from "/build/incl/pages/admin/exit-interviews.vue?macro=true";
import { default as films8vrc9kXUVkMeta } from "/build/incl/pages/admin/film-groups/films.vue?macro=true";
import { default as users7n116BADu1Meta } from "/build/incl/pages/admin/film-groups/users.vue?macro=true";
import { default as academy9wUoJXqZqSMeta } from "/build/incl/pages/admin/results/academy.vue?macro=true";
import { default as judgejEIZDfDFmyMeta } from "/build/incl/pages/admin/results/judge.vue?macro=true";
import { default as selectionKnQDMl40Q7Meta } from "/build/incl/pages/admin/results/selection.vue?macro=true";
import { default as sharingdyoym1uOXZMeta } from "/build/incl/pages/admin/sharing.vue?macro=true";
import { default as teamsTq3yL3raftMeta } from "/build/incl/pages/admin/teams.vue?macro=true";
import { default as usersdOwLwMqoDLMeta } from "/build/incl/pages/admin/users.vue?macro=true";
import { default as _91teamId_93HGE6HOeROXMeta } from "/build/incl/pages/archive/[year]/[teamId].vue?macro=true";
import { default as top_4510kNgCNiY3EDMeta } from "/build/incl/pages/archive/[year]/top-10.vue?macro=true";
import { default as indexCXLD76ntDzMeta } from "/build/incl/pages/archive/index.vue?macro=true";
import { default as box_45officeRz6wUDT2SPMeta } from "/build/incl/pages/box-office.vue?macro=true";
import { default as _91hash_93C1k0R0v1rRMeta } from "/build/incl/pages/film-challenge/guest-registration/[hash].vue?macro=true";
import { default as indexrKwXbk90PqMeta } from "/build/incl/pages/film-challenge/index.vue?macro=true";
import { default as inspirationaBcghGCD3VMeta } from "/build/incl/pages/film-challenge/inspiration.vue?macro=true";
import { default as indexOnukPaJQC6Meta } from "/build/incl/pages/film-challenge/studios/[studioId]/index.vue?macro=true";
import { default as _91teamId_93ojZDaSMqGiMeta } from "/build/incl/pages/film-challenge/studios/[studioId]/teams/[teamId].vue?macro=true";
import { default as createL2ByZNa5ZrMeta } from "/build/incl/pages/film-challenge/studios/[studioId]/teams/create.vue?macro=true";
import { default as the_45matchupZZUKDTCjeNMeta } from "/build/incl/pages/film-challenge/the-matchup.vue?macro=true";
import { default as _91filmGroupId_93iQTQuVgKjIMeta } from "/build/incl/pages/fivefest/academy/film-groups/[filmGroupId].vue?macro=true";
import { default as indexWJjNIYohz8Meta } from "/build/incl/pages/fivefest/academy/index.vue?macro=true";
import { default as _91submissionId_93HPgNF0ecvmMeta } from "/build/incl/pages/fivefest/films/[submissionId].vue?macro=true";
import { default as createo1bMW72fzhMeta } from "/build/incl/pages/fivefest/films/create.vue?macro=true";
import { default as indexnqAGIDxf2wMeta } from "/build/incl/pages/index.vue?macro=true";
import { default as _91filmGroupId_9358ri3I2aNaMeta } from "/build/incl/pages/judge/[filmGroupId].vue?macro=true";
import { default as indexGGX1KEPcqTMeta } from "/build/incl/pages/judge/index.vue?macro=true";
import { default as liveY25RbuAAq5Meta } from "/build/incl/pages/live.vue?macro=true";
import { default as _91filmGroupId_93GCO8sJAO2wMeta } from "/build/incl/pages/online-screening/[filmGroupId].vue?macro=true";
import { default as _91filmGroupId_93hwJ7z5XnPgMeta } from "/build/incl/pages/online-screening/five-fest/[filmGroupId].vue?macro=true";
import { default as indexQTK2dEOMBvMeta } from "/build/incl/pages/online-screening/five-fest/index.vue?macro=true";
import { default as indexl7yFGpo9SLMeta } from "/build/incl/pages/online-screening/index.vue?macro=true";
import { default as oryHyfaJ9dDH2Meta } from "/build/incl/pages/ory.vue?macro=true";
import { default as indexpsiI6PeIvTMeta } from "/build/incl/pages/prize-sponsor/index.vue?macro=true";
import { default as save_45the_45dates1NLt40g7obMeta } from "/build/incl/pages/save-the-dates.vue?macro=true";
import { default as index4iUrLg27I0Meta } from "/build/incl/pages/selection/[filmGroupId]/index.vue?macro=true";
import { default as reviewc1rDRR2VOPMeta } from "/build/incl/pages/selection/[filmGroupId]/review.vue?macro=true";
import { default as indexAKG846x7jFMeta } from "/build/incl/pages/selection/index.vue?macro=true";
export default [
  {
    name: _91filmGroupId_935Te4kwU3qmMeta?.name ?? "admin-audience-favorite-filmGroupId",
    path: _91filmGroupId_935Te4kwU3qmMeta?.path ?? "/admin/audience-favorite/:filmGroupId()",
    meta: _91filmGroupId_935Te4kwU3qmMeta || {},
    alias: _91filmGroupId_935Te4kwU3qmMeta?.alias || [],
    redirect: _91filmGroupId_935Te4kwU3qmMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/audience-favorite/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexGT0UMmJUoCMeta?.name ?? "admin-audience-favorite",
    path: indexGT0UMmJUoCMeta?.path ?? "/admin/audience-favorite",
    meta: indexGT0UMmJUoCMeta || {},
    alias: indexGT0UMmJUoCMeta?.alias || [],
    redirect: indexGT0UMmJUoCMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/audience-favorite/index.vue").then(m => m.default || m)
  },
  {
    name: checkinQlOB2SdPFPMeta?.name ?? "admin-awards-ceremony-checkin",
    path: checkinQlOB2SdPFPMeta?.path ?? "/admin/awards-ceremony/checkin",
    meta: checkinQlOB2SdPFPMeta || {},
    alias: checkinQlOB2SdPFPMeta?.alias || [],
    redirect: checkinQlOB2SdPFPMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/awards-ceremony/checkin.vue").then(m => m.default || m)
  },
  {
    name: exit_45interviewsoTDveIGw7WMeta?.name ?? "admin-exit-interviews",
    path: exit_45interviewsoTDveIGw7WMeta?.path ?? "/admin/exit-interviews",
    meta: exit_45interviewsoTDveIGw7WMeta || {},
    alias: exit_45interviewsoTDveIGw7WMeta?.alias || [],
    redirect: exit_45interviewsoTDveIGw7WMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/exit-interviews.vue").then(m => m.default || m)
  },
  {
    name: films8vrc9kXUVkMeta?.name ?? "admin-film-groups-films",
    path: films8vrc9kXUVkMeta?.path ?? "/admin/film-groups/films",
    meta: films8vrc9kXUVkMeta || {},
    alias: films8vrc9kXUVkMeta?.alias || [],
    redirect: films8vrc9kXUVkMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/film-groups/films.vue").then(m => m.default || m)
  },
  {
    name: users7n116BADu1Meta?.name ?? "admin-film-groups-users",
    path: users7n116BADu1Meta?.path ?? "/admin/film-groups/users",
    meta: users7n116BADu1Meta || {},
    alias: users7n116BADu1Meta?.alias || [],
    redirect: users7n116BADu1Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/film-groups/users.vue").then(m => m.default || m)
  },
  {
    name: academy9wUoJXqZqSMeta?.name ?? "admin-results-academy",
    path: academy9wUoJXqZqSMeta?.path ?? "/admin/results/academy",
    meta: academy9wUoJXqZqSMeta || {},
    alias: academy9wUoJXqZqSMeta?.alias || [],
    redirect: academy9wUoJXqZqSMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/results/academy.vue").then(m => m.default || m)
  },
  {
    name: judgejEIZDfDFmyMeta?.name ?? "admin-results-judge",
    path: judgejEIZDfDFmyMeta?.path ?? "/admin/results/judge",
    meta: judgejEIZDfDFmyMeta || {},
    alias: judgejEIZDfDFmyMeta?.alias || [],
    redirect: judgejEIZDfDFmyMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/results/judge.vue").then(m => m.default || m)
  },
  {
    name: selectionKnQDMl40Q7Meta?.name ?? "admin-results-selection",
    path: selectionKnQDMl40Q7Meta?.path ?? "/admin/results/selection",
    meta: selectionKnQDMl40Q7Meta || {},
    alias: selectionKnQDMl40Q7Meta?.alias || [],
    redirect: selectionKnQDMl40Q7Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/results/selection.vue").then(m => m.default || m)
  },
  {
    name: sharingdyoym1uOXZMeta?.name ?? "admin-sharing",
    path: sharingdyoym1uOXZMeta?.path ?? "/admin/sharing",
    meta: sharingdyoym1uOXZMeta || {},
    alias: sharingdyoym1uOXZMeta?.alias || [],
    redirect: sharingdyoym1uOXZMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/sharing.vue").then(m => m.default || m)
  },
  {
    name: teamsTq3yL3raftMeta?.name ?? "admin-teams",
    path: teamsTq3yL3raftMeta?.path ?? "/admin/teams",
    meta: teamsTq3yL3raftMeta || {},
    alias: teamsTq3yL3raftMeta?.alias || [],
    redirect: teamsTq3yL3raftMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/teams.vue").then(m => m.default || m)
  },
  {
    name: usersdOwLwMqoDLMeta?.name ?? "admin-users",
    path: usersdOwLwMqoDLMeta?.path ?? "/admin/users",
    meta: usersdOwLwMqoDLMeta || {},
    alias: usersdOwLwMqoDLMeta?.alias || [],
    redirect: usersdOwLwMqoDLMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93HGE6HOeROXMeta?.name ?? "archive-year-teamId",
    path: _91teamId_93HGE6HOeROXMeta?.path ?? "/archive/:year()/:teamId()",
    meta: _91teamId_93HGE6HOeROXMeta || {},
    alias: _91teamId_93HGE6HOeROXMeta?.alias || [],
    redirect: _91teamId_93HGE6HOeROXMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/archive/[year]/[teamId].vue").then(m => m.default || m)
  },
  {
    name: top_4510kNgCNiY3EDMeta?.name ?? "archive-year-top-10",
    path: top_4510kNgCNiY3EDMeta?.path ?? "/archive/:year()/top-10",
    meta: top_4510kNgCNiY3EDMeta || {},
    alias: top_4510kNgCNiY3EDMeta?.alias || [],
    redirect: top_4510kNgCNiY3EDMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/archive/[year]/top-10.vue").then(m => m.default || m)
  },
  {
    name: indexCXLD76ntDzMeta?.name ?? "archive",
    path: indexCXLD76ntDzMeta?.path ?? "/archive",
    meta: indexCXLD76ntDzMeta || {},
    alias: indexCXLD76ntDzMeta?.alias || [],
    redirect: indexCXLD76ntDzMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/archive/index.vue").then(m => m.default || m)
  },
  {
    name: box_45officeRz6wUDT2SPMeta?.name ?? "box-office",
    path: box_45officeRz6wUDT2SPMeta?.path ?? "/box-office",
    meta: box_45officeRz6wUDT2SPMeta || {},
    alias: box_45officeRz6wUDT2SPMeta?.alias || [],
    redirect: box_45officeRz6wUDT2SPMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/box-office.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93C1k0R0v1rRMeta?.name ?? "film-challenge-guest-registration-hash",
    path: _91hash_93C1k0R0v1rRMeta?.path ?? "/film-challenge/guest-registration/:hash()",
    meta: _91hash_93C1k0R0v1rRMeta || {},
    alias: _91hash_93C1k0R0v1rRMeta?.alias || [],
    redirect: _91hash_93C1k0R0v1rRMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/guest-registration/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexrKwXbk90PqMeta?.name ?? "film-challenge",
    path: indexrKwXbk90PqMeta?.path ?? "/film-challenge",
    meta: indexrKwXbk90PqMeta || {},
    alias: indexrKwXbk90PqMeta?.alias || [],
    redirect: indexrKwXbk90PqMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/index.vue").then(m => m.default || m)
  },
  {
    name: inspirationaBcghGCD3VMeta?.name ?? "film-challenge-inspiration",
    path: inspirationaBcghGCD3VMeta?.path ?? "/film-challenge/inspiration",
    meta: inspirationaBcghGCD3VMeta || {},
    alias: inspirationaBcghGCD3VMeta?.alias || [],
    redirect: inspirationaBcghGCD3VMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/inspiration.vue").then(m => m.default || m)
  },
  {
    name: indexOnukPaJQC6Meta?.name ?? "film-challenge-studios-studioId",
    path: indexOnukPaJQC6Meta?.path ?? "/film-challenge/studios/:studioId()",
    meta: indexOnukPaJQC6Meta || {},
    alias: indexOnukPaJQC6Meta?.alias || [],
    redirect: indexOnukPaJQC6Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/studios/[studioId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93ojZDaSMqGiMeta?.name ?? "film-challenge-studios-studioId-teams-teamId",
    path: _91teamId_93ojZDaSMqGiMeta?.path ?? "/film-challenge/studios/:studioId()/teams/:teamId()",
    meta: _91teamId_93ojZDaSMqGiMeta || {},
    alias: _91teamId_93ojZDaSMqGiMeta?.alias || [],
    redirect: _91teamId_93ojZDaSMqGiMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/studios/[studioId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: createL2ByZNa5ZrMeta?.name ?? "film-challenge-studios-studioId-teams-create",
    path: createL2ByZNa5ZrMeta?.path ?? "/film-challenge/studios/:studioId()/teams/create",
    meta: createL2ByZNa5ZrMeta || {},
    alias: createL2ByZNa5ZrMeta?.alias || [],
    redirect: createL2ByZNa5ZrMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/studios/[studioId]/teams/create.vue").then(m => m.default || m)
  },
  {
    name: the_45matchupZZUKDTCjeNMeta?.name ?? "film-challenge-the-matchup",
    path: the_45matchupZZUKDTCjeNMeta?.path ?? "/film-challenge/the-matchup",
    meta: the_45matchupZZUKDTCjeNMeta || {},
    alias: the_45matchupZZUKDTCjeNMeta?.alias || [],
    redirect: the_45matchupZZUKDTCjeNMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/film-challenge/the-matchup.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93iQTQuVgKjIMeta?.name ?? "fivefest-academy-film-groups-filmGroupId",
    path: _91filmGroupId_93iQTQuVgKjIMeta?.path ?? "/fivefest/academy/film-groups/:filmGroupId()",
    meta: _91filmGroupId_93iQTQuVgKjIMeta || {},
    alias: _91filmGroupId_93iQTQuVgKjIMeta?.alias || [],
    redirect: _91filmGroupId_93iQTQuVgKjIMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/fivefest/academy/film-groups/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexWJjNIYohz8Meta?.name ?? "fivefest-academy",
    path: indexWJjNIYohz8Meta?.path ?? "/fivefest/academy",
    meta: indexWJjNIYohz8Meta || {},
    alias: indexWJjNIYohz8Meta?.alias || [],
    redirect: indexWJjNIYohz8Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/fivefest/academy/index.vue").then(m => m.default || m)
  },
  {
    name: _91submissionId_93HPgNF0ecvmMeta?.name ?? "fivefest-films-submissionId",
    path: _91submissionId_93HPgNF0ecvmMeta?.path ?? "/fivefest/films/:submissionId()",
    meta: _91submissionId_93HPgNF0ecvmMeta || {},
    alias: _91submissionId_93HPgNF0ecvmMeta?.alias || [],
    redirect: _91submissionId_93HPgNF0ecvmMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/fivefest/films/[submissionId].vue").then(m => m.default || m)
  },
  {
    name: createo1bMW72fzhMeta?.name ?? "fivefest-films-create",
    path: createo1bMW72fzhMeta?.path ?? "/fivefest/films/create",
    meta: createo1bMW72fzhMeta || {},
    alias: createo1bMW72fzhMeta?.alias || [],
    redirect: createo1bMW72fzhMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/fivefest/films/create.vue").then(m => m.default || m)
  },
  {
    name: indexnqAGIDxf2wMeta?.name ?? "index",
    path: indexnqAGIDxf2wMeta?.path ?? "/",
    meta: indexnqAGIDxf2wMeta || {},
    alias: indexnqAGIDxf2wMeta?.alias || [],
    redirect: indexnqAGIDxf2wMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_9358ri3I2aNaMeta?.name ?? "judge-filmGroupId",
    path: _91filmGroupId_9358ri3I2aNaMeta?.path ?? "/judge/:filmGroupId()",
    meta: _91filmGroupId_9358ri3I2aNaMeta || {},
    alias: _91filmGroupId_9358ri3I2aNaMeta?.alias || [],
    redirect: _91filmGroupId_9358ri3I2aNaMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/judge/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexGGX1KEPcqTMeta?.name ?? "judge",
    path: indexGGX1KEPcqTMeta?.path ?? "/judge",
    meta: indexGGX1KEPcqTMeta || {},
    alias: indexGGX1KEPcqTMeta?.alias || [],
    redirect: indexGGX1KEPcqTMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/judge/index.vue").then(m => m.default || m)
  },
  {
    name: liveY25RbuAAq5Meta?.name ?? "live",
    path: liveY25RbuAAq5Meta?.path ?? "/live",
    meta: liveY25RbuAAq5Meta || {},
    alias: liveY25RbuAAq5Meta?.alias || [],
    redirect: liveY25RbuAAq5Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/live.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93GCO8sJAO2wMeta?.name ?? "online-screening-filmGroupId",
    path: _91filmGroupId_93GCO8sJAO2wMeta?.path ?? "/online-screening/:filmGroupId()",
    meta: _91filmGroupId_93GCO8sJAO2wMeta || {},
    alias: _91filmGroupId_93GCO8sJAO2wMeta?.alias || [],
    redirect: _91filmGroupId_93GCO8sJAO2wMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/online-screening/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93hwJ7z5XnPgMeta?.name ?? "online-screening-five-fest-filmGroupId",
    path: _91filmGroupId_93hwJ7z5XnPgMeta?.path ?? "/online-screening/five-fest/:filmGroupId()",
    meta: _91filmGroupId_93hwJ7z5XnPgMeta || {},
    alias: _91filmGroupId_93hwJ7z5XnPgMeta?.alias || [],
    redirect: _91filmGroupId_93hwJ7z5XnPgMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/online-screening/five-fest/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexQTK2dEOMBvMeta?.name ?? "online-screening-five-fest",
    path: indexQTK2dEOMBvMeta?.path ?? "/online-screening/five-fest",
    meta: indexQTK2dEOMBvMeta || {},
    alias: indexQTK2dEOMBvMeta?.alias || [],
    redirect: indexQTK2dEOMBvMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/online-screening/five-fest/index.vue").then(m => m.default || m)
  },
  {
    name: indexl7yFGpo9SLMeta?.name ?? "online-screening",
    path: indexl7yFGpo9SLMeta?.path ?? "/online-screening",
    meta: indexl7yFGpo9SLMeta || {},
    alias: indexl7yFGpo9SLMeta?.alias || [],
    redirect: indexl7yFGpo9SLMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/online-screening/index.vue").then(m => m.default || m)
  },
  {
    name: oryHyfaJ9dDH2Meta?.name ?? "ory",
    path: oryHyfaJ9dDH2Meta?.path ?? "/ory",
    meta: oryHyfaJ9dDH2Meta || {},
    alias: oryHyfaJ9dDH2Meta?.alias || [],
    redirect: oryHyfaJ9dDH2Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/ory.vue").then(m => m.default || m)
  },
  {
    name: indexpsiI6PeIvTMeta?.name ?? "prize-sponsor",
    path: indexpsiI6PeIvTMeta?.path ?? "/prize-sponsor",
    meta: indexpsiI6PeIvTMeta || {},
    alias: indexpsiI6PeIvTMeta?.alias || [],
    redirect: indexpsiI6PeIvTMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/prize-sponsor/index.vue").then(m => m.default || m)
  },
  {
    name: save_45the_45dates1NLt40g7obMeta?.name ?? "save-the-dates",
    path: save_45the_45dates1NLt40g7obMeta?.path ?? "/save-the-dates",
    meta: save_45the_45dates1NLt40g7obMeta || {},
    alias: save_45the_45dates1NLt40g7obMeta?.alias || [],
    redirect: save_45the_45dates1NLt40g7obMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/save-the-dates.vue").then(m => m.default || m)
  },
  {
    name: index4iUrLg27I0Meta?.name ?? "selection-filmGroupId",
    path: index4iUrLg27I0Meta?.path ?? "/selection/:filmGroupId()",
    meta: index4iUrLg27I0Meta || {},
    alias: index4iUrLg27I0Meta?.alias || [],
    redirect: index4iUrLg27I0Meta?.redirect || undefined,
    component: () => import("/build/incl/pages/selection/[filmGroupId]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewc1rDRR2VOPMeta?.name ?? "selection-filmGroupId-review",
    path: reviewc1rDRR2VOPMeta?.path ?? "/selection/:filmGroupId()/review",
    meta: reviewc1rDRR2VOPMeta || {},
    alias: reviewc1rDRR2VOPMeta?.alias || [],
    redirect: reviewc1rDRR2VOPMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/selection/[filmGroupId]/review.vue").then(m => m.default || m)
  },
  {
    name: indexAKG846x7jFMeta?.name ?? "selection",
    path: indexAKG846x7jFMeta?.path ?? "/selection",
    meta: indexAKG846x7jFMeta || {},
    alias: indexAKG846x7jFMeta?.alias || [],
    redirect: indexAKG846x7jFMeta?.redirect || undefined,
    component: () => import("/build/incl/pages/selection/index.vue").then(m => m.default || m)
  }
]